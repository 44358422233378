import React, {useState, useEffect, useCallback, useRef} from 'react';
import {Autocomplete, TextField, CircularProgress, FormControl} from '@mui/material';
import {useInfiniteQuery, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';
import {getShiptos} from "../services/IsfaService";

const sampleValue = {
    "name": null,
    "shiptoId": "589672",
    "salesChannel": "Territory",
    "billtoId": "038235",
    "address1": "8351 STATION RD. ROUTE 89",
    "address2": null,
    "address3": null,
    "city": "North East",
    "state": "PA",
    "postalCode": "16428",
    "country": "US",
    "salesTerritory": "TM-15",
    "creditHold": false
};

const ShiptoSearch = ({label, businessUnitCode, editable, required, initialValue = '', onChange}) => {

    console.log("Rendering ShiptoSearch - initialValue: ", initialValue);

    // const queryClient = useQueryClient();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState(initialValue);
    const [options, setOptions] = useState([]);
    const debounceTimeout = useRef(null);

    console.log("ShiptoSearch.inputValue: ", inputValue, ". Type: ", typeof inputValue);
    console.log("ShiptoSearch.options: ", options);
    console.log("open: ", open);

    useEffect(() => {
        if (initialValue !== '') {
            setInputValue(initialValue);
        }
    }, [initialValue]);

    const queryEnabled = !!businessUnitCode && (open || inputValue !== '');

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: ['shiptoItems', businessUnitCode, inputValue],
        queryFn: ({pageParam}) => getShiptos(pageParam, 10, {businessUnitCode, searchString: inputValue}),
        initialPageParam: 0,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.data.length ? allPages.length : undefined;
        },
        enabled: queryEnabled,
        staleTime: 5 * 60 * 1000, // 5 minutes
        cacheTime: 30 * 60 * 1000, // 30 minutes
    });

    useEffect(() => {
        if (data) {
            const fetchedOptions = data.pages.flatMap(page => page.data);
            setOptions(fetchedOptions);

            // search option where shiptoId matches inputValue
            const found = fetchedOptions.find(option => option.shiptoId === inputValue);
            if (found) {
                setValue(found);
            } else if (inputValue !== '') {
                setInputValue('');
            }
        }
    }, [data]);

    const handleScroll = (event) => {
        const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
        if (bottom && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    };

    const handleClose = () => {
        setOpen(false);
        // queryClient.invalidateQueries(['shiptoItems', businessUnitCode, inputValue]);
    };

    const handleInputChange = (event, newInputValue) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            const substringBeforeSpace = newInputValue.split(' ')[0];
            setInputValue(substringBeforeSpace);
        }, 500);
    };

    return (
        <FormControl fullWidth size="small" margin="dense" required={required}>
            <Autocomplete
                open={open}
                onOpen={() => setOpen(true)}
                onClose={handleClose}
                onChange={(event, newValue) => {
                    console.log("ShiptoSearch.onChange: ", newValue);
                    setValue(newValue);
                    onChange(newValue);
                }}
                getOptionLabel={(option) => {
                    // shiptoId - name (billto: billtoId, city: city)
                    let label = option.shiptoId;
                    if (option.name) {
                        label += ' - ' + option.name;
                    }
                    label += ' (Bill-to: ' + option.billtoId + ', City: ' + option.city + ')';
                    return label;
                }}
                options={options}
                loading={isFetching}
                onInputChange={handleInputChange}
                disabled={!editable}
                ListboxProps={{onScroll: handleScroll, sx: {maxHeight: 200}}}
                isOptionEqualToValue={(option, value) => option.shiptoId === value.shiptoId}
                value={value}
                // inputValue={inputValue}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        // variant="outlined"
                        size="small"
                        // disabled={!editable}
                        // value={inputValue}
                        // InputProps={{
                        //     ...params.InputProps,
                        //     endAdornment: (
                        //         <>
                        //             {isFetching ? <CircularProgress color="inherit" size={20}/> : null}
                        //             {params.InputProps.endAdornment}
                        //         </>
                        //     ),
                        // }}
                    />
                )}
            />
        </FormControl>
    );
};

export default ShiptoSearch;