import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {useLogout} from "./useLogout";
import {getRetailLocation} from "../services/RetailLocationService";

export function useRetailLocation(retailLocationId, enabled = true, callback = () => {}) {

    const [retailLocation, setRetailLocation] = useState({});

    const logout = useLogout();

    const {data, isError, error} = useQuery({
        queryKey: ['retailLocationId', retailLocationId],
        queryFn: () => getRetailLocation(retailLocationId),
        retry: false,
        enabled: enabled && !!retailLocationId
    })

    useEffect(() => {
        console.log('useRetailLocation useEffect error');
        if (isError) {
            if (error.response.status === 401) {
                logout();
            }
        }
    }, [isError, error]);

    useEffect(() => {
        console.log('useRetailLocation useEffect');
        if (data) {
            setRetailLocation(data);
            callback(data);
        }
    }, [data]);

    return retailLocation;
}