import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getSalesRegions} from "../services/IsfaService";
import {getSelectFitCategories, getSelectFitEbsCodes, getSelectFitLogoTypes} from "../services/RetailLocationService";

export function useSelectFitLogoTypes(enabled = true, resetKey, callback = () => {}) {

    const [selectFitLogoTypes, setSelectFitLogoTypes] = useState([]);

    const {data} = useQuery({
        queryKey: ['selectFitLogoTypes', resetKey],
        queryFn: () => getSelectFitLogoTypes(),
        retry: false,
        enabled: enabled,
    })

    useEffect(() => {
        console.log('selectFitLogoTypes useEffect');
        if (data) {
            setSelectFitLogoTypes(data.selectFitLogoTypes);
            callback(data.selectFitLogoTypes);
        }
    }, [data]);

    return selectFitLogoTypes;
}