import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getSalesRegions} from "../services/IsfaService";
import {getSelectFitCategories, getSelectFitEbsCodes} from "../services/RetailLocationService";

export function useSelectFitCategories(enabled = true, resetKey, callback = () => {}) {

    const [selectFitCategories, setSelectFitCategories] = useState([]);

    const {data} = useQuery({
        queryKey: ['selectFitCategories', resetKey],
        queryFn: () => getSelectFitCategories(),
        retry: false,
        enabled: enabled,
    })

    useEffect(() => {
        console.log('selectFitCategories useEffect');
        if (data) {
            setSelectFitCategories(data.selectFitCategories);
            callback(data.selectFitCategories);
        }
    }, [data]);

    return selectFitCategories;
}