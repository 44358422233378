import {Box, Button} from '@mui/material';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useLocalStorage} from "../../hooks/useLocalStorage";
import PageHeader from "../../components/PageHeader";
import MyBooleanMark from "../../components/MyBooleanMark";
import {useEvents} from "../../hooks/useEvents";
import {useEventTypes} from "../../hooks/useEventTypes";
import {useBookabilityLevels} from "../../hooks/useBookabilityLevels";
import {useSpecsVisibilities} from "../../hooks/useSpecsVisibilities";
import {toPercentage} from "../../util/NumberUtil";
import {useNavigate} from "react-router-dom";
import {getCurrentUser} from "../../util/AuthUtil";
import routes from "../../routes";
import {REP_TYPES} from "../../constants";
import {Cancel, CheckCircle} from "@mui/icons-material";
import {useReps} from "../../hooks/useReps";
import {useRetailLocations} from "../../hooks/useRetailLocations";
import Grid from "@mui/material/Grid";
import RepsFilter from "../reps/RepsFilter";
import RetailLocationsFilter from "./RetailLocationsFilter";
import RepModal from "../reps/RepModal";
import RetailLocationModal from "./RetailLocationModal";

const RetailLocationsTable = () => {

    console.log("Rendering RetailLocationsTable");

    const [filter, setFilter] = useState({
        businessUnitCode: '',
        active: '',
        selectFitEbsCodeId: '',
        selectFitCategoryId: '',
    });
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });

    const retailLocationModalRef = useRef();

    const navigate = useNavigate();
    const currentUser = getCurrentUser();

    useEffect(() => {
        if (!routes.find(route => route.key === 'retail-locations').visibleRepTypes.includes(currentUser.repType.id)) {
            navigate(REP_TYPES[currentUser.repType.id].defaultRoute);
        }
    }, [currentUser, navigate]);

    const [columnWidths, setColumnWidths] = useLocalStorage('retailLocationsTableColumnWidths', {});

    const columns = useMemo(() => {
        return [
            {field: 'id', headerName: 'ID', width: columnWidths.id ?? 70},
            {field: 'businessUnitCode', headerName: 'BUSINESS UNIT', width: columnWidths.businessUnitCode ?? 130},
            {field: 'shiptoId', headerName: 'SHIPTO', width: columnWidths.shiptoId ?? 100},
            {
                field: 'selectFitEbsCode',
                headerName: 'SELECT FIT EBS CODE',
                width: columnWidths.selectFitEbsCode ?? 250,
                renderCell: (params) => {
                    return params.value ? params.value.name : 'NA';
                },
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1.name.localeCompare(v2.name);
                },
            },
            {
                field: 'selectFitCategory',
                headerName: 'SELECT FIT CATEGORY',
                width: columnWidths.selectFitCategory ?? 250,
                renderCell: (params) => {
                    return params.value ? params.value.name : 'NA';
                },
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1.name.localeCompare(v2.name);
                },
            },
            {
                field: 'selectFitLogoType',
                headerName: 'SELECT FIT LOGO TYPE',
                width: columnWidths.selectFitLogoType ?? 250,
                renderCell: (params) => {
                    return params.value ? params.value.name : 'NA';
                },
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1.name.localeCompare(v2.name);
                },
            },
            {field: 'displayName', headerName: 'NAME', width: columnWidths.displayName ?? 100},
            {field: 'street', headerName: 'STREET', width: columnWidths.street ?? 100},
            {field: 'city', headerName: 'CITY', width: columnWidths.city ?? 100},
            {field: 'state', headerName: 'STATE', width: columnWidths.state ?? 80},
            {field: 'postalCode', headerName: 'POSTAL CODE', width: columnWidths.postalCode ?? 80},
            {field: 'countryCode', headerName: 'COUNTRY', width: columnWidths.countryCode ?? 80},
            {
                field: 'onWebsite',
                headerName: 'ON WEBSITE',
                width: columnWidths.onWebsite ?? 80,
                renderCell: (params) => (
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                        {params.value ? <CheckCircle color="success"/> : <Cancel color="error"/>}
                    </Box>
                ),
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1 - v2;
                },
            },
            {field: 'bookingLink', headerName: 'BOOKING LINK', width: columnWidths.bookingLink ?? 100},
            {
                field: 'active',
                headerName: 'ACTIVE',
                width: columnWidths.active ?? 80,
                renderCell: (params) => (
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                        {params.value ? <CheckCircle color="success"/> : <Cancel color="error"/>}
                    </Box>
                ),
                sortComparator: (v1, v2) => {
                    if (v1 === null) return 1;
                    if (v2 === null) return -1;
                    return v1 - v2;
                },
            },
            {field: 'latitude', headerName: 'LATITUDE', width: columnWidths.latitude ?? 100},
            {field: 'longitude', headerName: 'LONGITUDE', width: columnWidths.longitude ?? 100},
        ];
    }, []);

    const [retailLocations, totalRowCount, refetchRetailLocations] = useRetailLocations(
        paginationModel.page,
        paginationModel.pageSize,
        filter.businessUnitCode,
        filter.active,
        filter.selectFitEbsCodeId,
        filter.selectFitCategoryId,
    );

    const handleColumnResize = (params) => {
        setColumnWidths(prevWidths => ({
            ...prevWidths,
            [params.colDef.field]: params.width,
        }));
    };

    const filterChangeFunctions = {
        businessUnitCode: (value) => {
            setFilter(prevState => ({
                ...prevState,
                businessUnitCode: value,
            }));
        },
        active: (value) => {
            setFilter(prevState => ({
                ...prevState,
                active: value,
            }));
        },
        selectFitEbsCodeId: (value) => {
            setFilter(prevState => ({
                ...prevState,
                selectFitEbsCodeId: value,
            }));
        },
        selectFitCategoryId: (value) => {
            setFilter(prevState => ({
                ...prevState,
                selectFitCategoryId: value,
            }));
        },
    }

    function renderControlPanel() {
        return (
            <Box pt={1}>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        display: 'flex',
                        // justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Grid item xs={10}>
                        <RetailLocationsFilter
                            filter={filter}
                            filterChangeFunctions={filterChangeFunctions}
                            setFilter={setFilter}
                        />
                    </Grid>
                    <Grid item xs={2} sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        // alignItems: 'center',
                    }}>
                        <Button variant="contained" color="primary"
                                onClick={() => retailLocationModalRef.current.handleOpen()}>
                            + New Retail Location
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    function handleRowClick(params) {
        console.log(params);
        retailLocationModalRef.current.handleOpen(params.row.id);
    }

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <PageHeader title="Manage Retail Locations" children={renderControlPanel()}/>

                <Box sx={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                }}>
                    <DataGrid
                        columns={columns}
                        rows={retailLocations}
                        paginationMode="server"
                        rowCount={totalRowCount}
                        pageSizeOptions={[10, 20, 50]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        slots={{toolbar: GridToolbar}}
                        onRowClick={handleRowClick}
                        disableRowSelectionOnClick={true}
                        onColumnWidthChange={handleColumnResize}
                        disableColumnFilter={true}
                        sx={{
                            height: '100%',
                            position: 'absolute',
                            width: '100%',
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                            // fontSize: '1rem',
                        }}
                    />
                </Box>
                <RetailLocationModal ref={retailLocationModalRef} refetchRetailLocations={refetchRetailLocations}/>
            </Box>
        </>
    );
};

export default RetailLocationsTable;
