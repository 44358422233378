import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getSalesRegions} from "../services/IsfaService";
import {getSelectFitEbsCodes} from "../services/RetailLocationService";

export function useSelectFitEbsCodes(enabled = true, resetKey, callback = () => {}) {

    const [selectFitEbsCodes, setSelectFitEbsCodes] = useState([])

    const {data} = useQuery({
        queryKey: ['selectFitEbsCodes', resetKey],
        queryFn: () => getSelectFitEbsCodes(),
        retry: false,
        enabled: enabled,
    })

    useEffect(() => {
        console.log('selectFitEbsCodes useEffect');
        if (data) {
            setSelectFitEbsCodes(data.selectFitEbsCodes);
            callback(data.selectFitEbsCodes);
        }
    }, [data]);

    return selectFitEbsCodes;
}