import {
    Box, Dialog, DialogContent, DialogTitle, FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import {getShiptos} from '../../services/IsfaService';
import {DataGrid} from '@mui/x-data-grid';
import {useLogout} from "../../hooks/useLogout";

const SalesTerritoriesModal = forwardRef(({payload, closeModal}, ref) => {

    const [salesTerritory, setSalesTerritory] = useState(payload.data.length > 0 ? payload.data[0] : '');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });
    const [shiptos, setShiptos] = useState([]);
    const [totalRowCount, setTotalRowCount] = useState(0);

    useImperativeHandle(ref, () => ({
        setSalesTerritory
    }), []);

    const logout = useLogout();

    console.log('SalesTerritoriesModal')
    console.log(payload);
    // console.log(salesTerritory);

    useEffect(() => {
        if (salesTerritory !== '') {
            getShiptos(paginationModel.page, paginationModel.pageSize, {salesTerritory}).then((res) => {
                console.log(res.data);
                setShiptos(res.data);
                setTotalRowCount(parseInt(res.headers['x-total-count']));
                console.log('total count: ' + res.headers['x-total-count']);
            }).catch((err) => {
                console.log(err);
                if (err.response.status === 401) {
                    logout();
                }
            });
        }
    }, [paginationModel, salesTerritory]);

    const columns = useMemo(() => {
        return [
            {field: 'shiptoId', headerName: 'SHIPTO'},
            {field: 'name', headerName: 'NAME', width: 200},
            {field: 'billtoId', headerName: 'BILLTO'},
            {field: 'city', headerName: 'CITY', width: 150},
            {field: 'state', headerName: 'STATE'},
            {field: 'country', headerName: 'COUNTRY'},
        ];
    }, []);

    return (
        <Dialog
            open={payload.open}
            onClose={closeModal}
            fullWidth={true}
            maxWidth="md"
            sx={{
                '& .MuiDialog-paper': {
                    height: '80%',
                },
            }}
        >
            <DialogTitle>Shipto Accounts</DialogTitle>
            <DialogContent sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <FormControl fullWidth sx={{mt: 1}} size="small">
                    <InputLabel id="sales-territory">Sales Territory</InputLabel>
                    <Select
                        labelId="sales-territory"
                        id="sales-territory-select"
                        label="Sales Territory"
                        value={salesTerritory}
                        onChange={(event) => setSalesTerritory(event.target.value)}
                    >
                        {payload.data && payload.data.map((territory) => {
                            return (
                                <MenuItem key={territory} value={territory}>{territory}</MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <Box sx={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                }}>
                    <DataGrid
                        columns={columns}
                        rows={shiptos}
                        getRowId={(row) => row.shiptoId}
                        paginationMode="server"
                        rowCount={totalRowCount}
                        pageSizeOptions={[10, 20, 50]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        density="compact"
                        sx={{height: '100%', position: 'absolute', width: '100%'}}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
});

export default SalesTerritoriesModal;