import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getSalesRegions} from "../services/IsfaService";

export function useSalesRegions(businessUnitCode, userId, enabled = true, callback = () => {}) {

    const [salesRegions, setSalesRegions] = useState([])

    const {data} = useQuery({
        queryKey: ['salesRegions', businessUnitCode, userId],
        queryFn: () => getSalesRegions(businessUnitCode, userId),
        retry: false,
        enabled: enabled && !!businessUnitCode,
    })

    useEffect(() => {
        console.log('useSalesRegions useEffect');
        if (data) {
            const newSalesRegions = data.map((item) => ({id: item.name, ...item}));
            setSalesRegions(newSalesRegions);
            callback(newSalesRegions);
        }
    }, [data]);

    return salesRegions;
}