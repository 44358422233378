import Grid from '@mui/material/Grid';
import {useCurrentUser} from "../../hooks/useCurrentUser";
import {useMemo, useState} from "react";
import {useSalesRegions} from "../../hooks/useSalesRegions";
import {REP_TYPES} from "../../constants";
import {useSalesTerritories} from "../../hooks/useSalesTerritories";
import {useBusinessUnitCodes} from "../../hooks/useBusinessUnitCodes";
import MySelect from "../../components/MySelect";
import MyMultiSelect from "../../components/MyMultiSelect";
import {useSelectFitEbsCodes} from "../../hooks/useSelectFitEbsCodes";
import {useSelectFitCategories} from "../../hooks/useSelectFitCategories";

const activeOptions = [
    {id: '', name: '-- CLEAR --'},
    {id: true, name: 'Yes'},
    {id: false, name: 'No'},
];

const RetailLocationsFilter = ({filter, filterChangeFunctions, setFilter}) => {

    console.log('Rendering RetailLocationsFilter');

    const [businessUnitCodes, setBusinessUnitCodes] = useState([]);
    const [selectFitEbsCodes, setSelectFitEbsCodes] = useState([]);
    const [selectFitCategories, setSelectFitCategories] = useState([]);

    const [currentUser, _updateCurrentUser] = useCurrentUser(
        false,
    );
    useBusinessUnitCodes(
        true,
        null,
        (data) => {
            let options = [{id: '', name: '-- CLEAR --'}, ...data];
            setBusinessUnitCodes(options);
        }
    );

    useSelectFitEbsCodes(
        true,
        null,
        (data) => {
            let options = [{id: '', name: '-- CLEAR --'}, ...data];
            setSelectFitEbsCodes(options);
        }
    );

    useSelectFitCategories(
        true,
        null,
        (data) => {
            let options = [{id: '', name: '-- CLEAR --'}, ...data];
            setSelectFitCategories(options);
        }
    );

    return (
        <Grid container spacing={1}>
            <Grid item xs={3}>
                <MySelect
                    label="Active"
                    options={activeOptions}
                    value={filter.active}
                    onChange={(value) => {
                        filterChangeFunctions.active(value)
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <MySelect
                    label="Business Unit"
                    options={businessUnitCodes}
                    value={filter.businessUnitCode}
                    onChange={(value) => {
                        filterChangeFunctions.businessUnitCode(value);
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <MySelect
                    label="Select Fit EBS Code"
                    options={selectFitEbsCodes}
                    value={filter.selectFitEbsCodeId}
                    onChange={(value) => {
                        filterChangeFunctions.selectFitEbsCodeId(value);
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <MySelect
                    label="Select Fit Category"
                    options={selectFitCategories}
                    value={filter.selectFitCategoryId}
                    onChange={(value) => {
                        filterChangeFunctions.selectFitCategoryId(value);
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default RetailLocationsFilter;