import {FormControl, TextField} from "@mui/material";
import {useState} from "react";

const MyTextField = ({value = '', editable, required, maxLength, onChange, ...props}) => {

    // console.log('Rendering MyTextField: ' + props.label)

    return (
        <FormControl fullWidth margin="dense">
            <TextField
                value={value}
                InputLabelProps={{shrink: !!value}}
                onChange={onChange}
                required={required}
                disabled={!editable}
                size="small"
                autoComplete={'new-password'}
                inputProps={{maxLength: maxLength}}
                {...props}
            />
        </FormControl>
    );
}

export default MyTextField;